@import './assets/styles/app.scss';
/* You can add global styles to this file, and also import other style files */
.cds-page-header,
.cds-page-header > * {
  height: 4rem;
}

.cds-dropdown-content {
  z-index: 8000;
}

cds-popover {
  .cds-dropdown-content {
    z-index: 8000;
  }
}

.cds-main-page-layout:has(.cds-page-layout-header:empty) {
  height: calc(100vh - 64px);
}

#cds-navigation-menu-nav {
  .cds-nav-item.-selected > .cds-nav-link {
    color: white !important;
    --color-interactive-01: transparent !important;
  }
}
